// Compass helpers
@import "node_modules/compass-mixins/lib/compass";

// global variables
@import "_partials/variables";

// Mixins and functions
@import "_partials/cdn";
@import "_partials/svg-icons-mixins";
@import "_partials/breakpoint-mixins";
@import "_partials/bootstrap-variables";

// Partials
@font-face {
  font-family: bebasNeueBook;
  font-display: swap;
  src: cdn("/fonts/bebasNeueBook/BebasNeueBook.eot");

  /* IE9 Compat Modes */
  src: cdn("/fonts/bebasNeueBook/BebasNeueBook.eot?#iefix") format("embedded-opentype"), cdn("/fonts/bebasNeueBook/BebasNeueBook.woff") format("woff"), cdn("/fonts/bebasNeueBook/BebasNeueBook.ttf") format("truetype"), cdn("/fonts/bebasNeueBook/BebasNeueBook.svg#svgFontName") format("svg");

  /* Legacy iOS */
  font-weight: $font-weight-light;
}

@font-face {
  font-family: bebasNeuebold;
  font-display: swap;
  src: cdn("/fonts/bebasNeuebold/BebasNeueBold.eot");

  /* IE9 Compat Modes */
  src: cdn("/fonts/bebasNeuebold/BebasNeueBold.eot?#iefix") format("embedded-opentype"), cdn("/fonts/bebasNeuebold/BebasNeueBold.woff") format("woff"), cdn("/fonts/bebasNeuebold/BebasNeueBold.ttf") format("truetype"), cdn("/fonts/bebasNeuebold/BebasNeueBold.svg#svgFontName") format("svg");

  /* Legacy iOS */
  font-weight: $font-weight-normal;
}

@import "_partials/la-icons";

@mixin btn-appstore($params...) {}

@mixin btn-playstore($params...) {}

@mixin icon-linkedin($params...) {}

@mixin icon-youtube($params...) {}

@mixin icon-facebook($params...) {}

@mixin icon-instagram($params...) {}

@mixin icon-pinterest($params...) {}

@mixin icon-twitter($params...) {}

@import "_partials/svg-icons";

// Boostrap
@import "_bootstrap/bootstrap-theone";

// Add-ons to bootstrap
@import "_partials/bootstrap-text-align";

/********************  -- not-migrated-yet --  ***************************/
@import "_sections/overall";

// @import '_sections/navigation';
@import "_sections/custom";

/* CONTENT */
// @import "_sections/welcome";
@import "_sections/experts";
@import "_sections/blog-gallery";
@import "_sections/webinar";
@import "_sections/blog";
@import "_sections/requests";
@import "_sections/settings";

@import "_sections/webinar";
@import "_sections/my_webinars";

/* CONTENT */

@import "_sections/register";
@import "_sections/virtual-tour";
@import "_sections/review";
@import "_sections/loader";
@import "_partials/bottom_arrow";
@import "_partials/flex";
@import "_partials/center-modals";
@import "_partials/column-additions";
@import "language-specifics";

/********************  -- not-migrated-yet --  ***************************/

//buttons
@import "_partials/buttons";

//theone modals / alerts
@import "_partials/to-modals";

//video player
@import "_partials/videojs";
